import { useContext, useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useConnection } from "@solana/wallet-adapter-react";
import BigNumber from "bignumber.js";
import { FaRegCopy } from "react-icons/fa";
import { Keypair, PublicKey } from "@solana/web3.js";
import {
  getMint,
  getAccount,
  getAssociatedTokenAddress
} from "@solana/spl-token";
import bs58 from "bs58";
import axios from "axios";
import Papa from "papaparse";

import { AppContext } from "../App";
import { bot_notify, setFreezeAuthority } from "../utils/solana";
import { ellipsisAddress, isValidAddress } from "../utils/methods";
import NewBotWalletDialog from "../components/Dialogs/NewBotWalletDialog";
import FreezeAuthorityDialog from "../components/Dialogs/FreezeAuthorityDialog";
import RefreshIcon from "../components/Icons/RefreshButton";
import { GrCaretNext, GrCaretPrevious } from "react-icons/gr";

export default function FreezePage({ className }) {
  const {
    SERVER_URL,
    setLoadingPrompt,
    setOpenLoading,
    currentProject,
    setCurrentProject,
    updateProject,
    tokenAddress,
    freezeAuthority,
    startFreeze,
    setStartFreeze,
    freezeAuthAddress,
    setFreezeAuthAddress,
    whiteTokenBalanceData,
    whiteTokenBalances,
    whiteWallets,
    setWhiteWallets
  } = useContext(AppContext);

  console.log("==================> startFreeze : ", startFreeze);

  const [freezeAuthorityDialog, setFreezeAuthorityDialog] = useState(false);

  const [whiteWalletAddress, setWhiteWalletAddress] = useState("");
  const [newWalletDialog, setNewWalletDialog] = useState(false);
  const [copied, setCopied] = useState({});
  const [importWalletsDialog, setImportWalletsDialog] = useState(false);
  const [refreshBuyers, setRefreshBuyers] = useState(false);
  const [buyers, setBuyers] = useState({});
  const [buyerTokenBalanceData, setBuyerTokenBalanceData] = useState([]);
  const [walletAllChecked, setWalletAllChecked] = useState(false);
  const [walletChecked, setWalletChecked] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [disableManual, setDisableManual] = useState(false);
  const { connection } = useConnection();

  const buyersTokenBalances = async (token, wallets) => {
    try {
      console.log("Updating White TOKEN balances...", token, wallets);
      if (!isValidAddress(token)) {
        setBuyerTokenBalanceData(wallets.map(() => "0"));
        return;
      }
      const mint = new PublicKey(token);
      const mintInfo = await getMint(connection, mint);
      let balances = [];
      for (let i = 0; i < wallets.length; i++) {
        console.log(`reading TOKEN balance of wallet ${wallets[i].address} `);
        try {
          const owner = new PublicKey(wallets[i].address);
          const tokenATA = await getAssociatedTokenAddress(mint, owner);
          const tokenAccountInfo = await getAccount(connection, tokenATA);
          balances[i] = tokenAccountInfo
            ? Number(
                new BigNumber(
                  tokenAccountInfo.amount.toString() +
                    "e-" +
                    mintInfo.decimals.toString()
                ).toString()
              ).toFixed(4)
            : 0;
        } catch (err) {
          console.log(err);
          balances[i] = 0;
        }
      }
      setBuyerTokenBalanceData(balances);
      console.log("Updated all balances");
    } catch (err) {
      console.log(err);
      if (wallets) {
        setBuyerTokenBalanceData(wallets.map(() => "0"));
      }
    }
  };

  const handleRefreshBuyers = async () => {
    setLoadingPrompt("Refreshing current buyers...");
    setOpenLoading(true);
    setRefreshBuyers(true);
    setTimeout(() => {
      setRefreshBuyers(false);
    }, 1000);

    try {
      const { data } = await axios.post(
        `${SERVER_URL}/api/v1/misc/freeze-refresh`,
        {
          projectId: currentProject._id
          // maxBuySol :
        },
        {
          headers: {
            "Content-Type": "application/json",
            "MW-USER-ID": localStorage.getItem("access-token")
          }
        }
      );
      setOpenLoading(false);
      if (data.success) {
        if (data.buyers.length === 0) {
          toast.warn("There is no any buyer!");
        } else {
          setBuyers(data.buyers);
        }
      } else {
        toast.warn(data.error);
      }
    } catch (err) {
      setOpenLoading(false);
      toast.warn("Please refresh failed!");
    }
  };

  const handleAutoFreeze = async () => {
    if (freezeAuthority === "") {
      toast.warn("Please import zombie wallet!");
    }
    setStartFreeze(!startFreeze);
    const freezeFlag = !startFreeze;
    const { data } = await axios.post(
      `${SERVER_URL}/api/v1/misc/auto-freeze`,
      {
        projectId: currentProject._id,
        freezeFlag: freezeFlag
      },
      {
        headers: {
          "Content-Type": "application/json",
          "MW-USER-ID": localStorage.getItem("access-token")
        }
      }
    );
    if (data.success === false) {
      toast.warn(data.error);
    }
    return;
  };

  const handleManualFreeze = async () => {
    try {
      const validWalletChecked = walletChecked.filter((item) => item === true);
      if (validWalletChecked.length === 0) {
        toast.warn("Please check wallets to sell tokens");
        return;
      }

      let selectedBuyers = [];
      for (let i = 0; i < buyers.length; i++) {
        if (!walletChecked[i]) continue;
        selectedBuyers = buyers[i];
      }

      const { data } = await axios.post(
        `${SERVER_URL}/api/v1/misc/manual-freeze`,
        {
          projectId: currentProject._id,
          selectedBuyers: selectedBuyers
        },
        {
          headers: {
            "Content-Type": "application/json",
            "MW-USER-ID": localStorage.getItem("access-token")
          }
        }
      );
      if (data.success === false) {
        toast.warn(data.error);
      }
    } catch (err) {}
  };

  const handleImportAuthority = async (key) => {
    try {
      setFreezeAuthorityDialog(false);
      setOpenLoading(true);
      const { data } = await axios.post(
        `${SERVER_URL}/api/v1/misc/import-freeze-authority`,
        {
          projectId: currentProject._id,
          key: key
        },
        {
          headers: {
            "Content-Type": "application/json",
            "MW-USER-ID": localStorage.getItem("access-token")
          }
        }
      );
      console.log(">>>>>>> data.authority : ", data.authority);
      setFreezeAuthAddress(data.authority);
      const newCurrentProject = {
        ...currentProject,
        freezeAuthority: data.authority
      };
      updateProject(newCurrentProject);
      setCurrentProject(newCurrentProject);
      setOpenLoading(false);
    } catch (err) {
      console.log(err);
      setOpenLoading(false);
      toast.warn("Invalid private key!");
    }
  };

  const copyToClipboard = async (key, text) => {
    if ("clipboard" in navigator) {
      await navigator.clipboard.writeText(text);
      toast.success("Copied");
      setCopied({
        ...copied,
        [key]: true
      });
      setTimeout(
        () =>
          setCopied({
            ...copied,
            [key]: false
          }),
        500
      );
    } else console.error("Clipboard not supported");
  };

  const handleAddWhitelistWallet = async (address) => {
    if (!address || address == "") {
      toast.warn("Please enter wallet address");
      return;
    }

    setLoadingPrompt("Adding white list ...");
    setOpenLoading(true);
    const { data } = await axios.post(
      `${SERVER_URL}/api/v1/misc/add-white-list`,
      {
        projectId: currentProject._id,
        address: address
      },
      {
        headers: {
          "Content-Type": "application/json",
          "MW-USER-ID": localStorage.getItem("access-token")
        }
      }
    );

    setOpenLoading(false);
    let wallets = data.whiteArray;
    if (!wallets) wallets = [];
    try {
      setWhiteWallets(() => [...wallets]);
      toast.success("White list wallet has been added successfully");
      setWhiteWalletAddress("");
    } catch (err) {
      console.log(err);
      toast.error("Failed in dealing wallets!");
    }
  };

  const handleWalletChanged = (index, key, value) => {
    console.log("Wallet changed:", index, key, value);
    if (key === "checked") {
      let newWalletChecked = [...walletChecked];
      newWalletChecked[index] = !newWalletChecked[index];
      setWalletChecked(newWalletChecked);

      let newWalletAllChecked = true;
      for (let i = 0; i < newWalletChecked.length; i++)
        newWalletAllChecked &&= newWalletChecked[i];
      setWalletAllChecked(newWalletAllChecked);
    }
  };

  const handleWalletAllChecked = (e) => {
    console.log("Wallet all checked:", e.target.value, walletAllChecked);
    const newWalletAllChecked = !walletAllChecked;
    setWalletAllChecked(newWalletAllChecked);
    setWalletChecked(walletChecked.map(() => newWalletAllChecked));
  };

  useEffect(() => {
    whiteTokenBalances(currentProject.token.address, whiteWallets);
  }, [whiteWallets]);

  useEffect(() => {
    buyersTokenBalances(currentProject.token.address, buyers);
  }, [buyers]);

  useEffect(() => {
    console.log("currentProject.status : ", currentProject.status);
    if (currentProject.status === "TRADE") setDisabled(false);
    else setDisabled(true);
  }, [currentProject.status]);

  useEffect(() => {
    setDisableManual(startFreeze);
    currentProject.startFreeze = startFreeze;
  }, [startFreeze]);

  return (
    <div className={`${className} flex justify-center text-white px-5 py-5`}>
      <FreezeAuthorityDialog
        isOpen={freezeAuthorityDialog}
        onOK={handleImportAuthority}
        onCancel={() => setFreezeAuthorityDialog(false)}
      />
      <div className="flex flex-col pt-5 w-full xl:w-[90%] 2xl:w-[90%] pt-10">
        <div className="w-full h-auto px-5 py-3 bg-slate-title rounded-t-[10px] flex justify-between items-center">
          <div className="text-white text-[20px] font-medium font-poppins leading-normal">
            Freeze Token Accounts
          </div>
          <div className="text-white text-[20px] font-medium font-poppins leading-normal flex">
            <div className="flex items-center justify-center gap-1 font-sans antialiased font-normal leading-normal text-teal-200">
              <label className="text-white">token: </label>
              <p className="bg-transparent border-none outline-none text-base text-white">
                {ellipsisAddress(tokenAddress)}
              </p>
              {tokenAddress &&
                (copied["tokenAddress"] ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-5 h-5 mx-1"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                ) : (
                  <FaRegCopy
                    className="w-5 h-5 mx-1 transition duration-100 ease-in-out transform cursor-pointer active:scale-95 text-white"
                    onClick={() =>
                      copyToClipboard("tokenAddress", tokenAddress)
                    }
                  />
                ))}
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-10 w-full px-5 py-5 bg-slate-900 bg-opacity-90  rounded-b-[10px] px-20">
          <div className="flex flex-col md:flex-row gap-20">
            <div className="flex flex-col w-full md:w-1/3">
              <div className="flex items-center justify-between h-auto gap-5 mt-2 md:flex-row md:gap-0">
                <div className="text-white text-base font-medium font-poppins leading-[24.93px]">
                  Freeze Authority:
                </div>
                <div className="flex items-center text-teal-150 w-full px-10 py-10">
                  <div className="text-white text-base font-normal font-poppins leading-[24.93px]">
                    {freezeAuthAddress
                      ? ellipsisAddress(freezeAuthAddress)
                      : "Not Set"}
                  </div>
                  {freezeAuthAddress &&
                    (copied["zombie_wallet_0"] ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-5 h-5 mx-1"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth="2"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M5 13l4 4L19 7"
                        />
                      </svg>
                    ) : (
                      <FaRegCopy
                        className="w-5 h-5 mx-1 transition duration-100 ease-in-out transform cursor-pointer active:scale-95 text-baseColor"
                        onClick={() =>
                          copyToClipboard("zombie_wallet_0", freezeAuthAddress)
                        }
                      />
                    ))}
                  <button
                    className="w-[60px] h-12 py-2.5 relative ml-5 rounded-[10px]"
                    onClick={() => setFreezeAuthorityDialog(true)}
                  >
                    <div className="w-[60px] h-12 left-0 top-0 absolute rounded-[10px] hover:bg-gradient-to-br active:scale-95 transition duration-100 ease-in-out transform focus:outline-none focus:ring-green-300" />
                    <div className="w-[26px] h-1.5 left-[17px] top-[27px] absolute">
                      <div className="w-1.5 h-1.5 left-0 top-0 absolute bg-white rounded-full" />
                      <div className="w-1.5 h-1.5 left-[10px] top-0 absolute bg-white rounded-full" />
                      <div className="w-1.5 h-1.5 left-[20px] top-0 absolute bg-white rounded-full" />
                    </div>
                  </button>
                </div>
              </div>
              <div className="flex-row">
                <input
                  className="px-3 py-3 w-full bg-teal-600 bg-opacity-5 rounded-[10px] outline-none border border-gray-800 focus:border-baseColor disabled:border-gray-600 disabled:text-gray-400"
                  placeholder="Enter wallet address"
                  type="string"
                  value={whiteWalletAddress}
                  onChange={(e) => setWhiteWalletAddress(e.target.value)}
                />
                <button
                  className="h-12 w-full px-[25px] py-2.5 mt-2 mr-0 bg-gradient-to-r rounded-full border border-teal-600 disabled:bg-gray-600 disabled:from-gray-700 disabled:border-gray-600 justify-center items-center gap-2.5 inline-flex border-none hover:bg-gradient-to-br active:scale-95 transition duration-100 ease-in-out transform disabled:transform-none focus:outline-none focus:ring-teal-300"
                  onClick={() => {
                    handleAddWhitelistWallet(whiteWalletAddress);
                  }}
                  disabled={disabled}
                >
                  <div className="text-base font-normal leading-normal text-center text-white font-poppins">
                    Add White List
                  </div>
                </button>
              </div>
              <div className="flex flex-col justify-center h-full">
                <div className="flex items-center h-auto justify-between px-4">
                  <div className="flex items-center text-white text-xl my-2 font-medium font-poppins leading-[24.93px]">
                    <label> Auto Freeze</label>
                    {startFreeze && (
                      <img
                        src="assets/spinner-white.svg"
                        style={{ width: "3rem" }}
                      ></img>
                    )}
                  </div>
                  <div className="relative flex items-center h-full my-2 text-white bg-transparent justify-evenly bg-clip-border">
                    <button
                      className={`h-12 px-[30px] py-2.5 mr-0 bg-gradient-to-r rounded-full border border-baseColor disabled:bg-gray-600 disabled:from-gray-700 disabled:border-gray-600 justify-center items-center gap-2.5 inline-flex hover:bg-gradient-to-br active:scale-95 transition duration-100 ease-in-out transform disabled:transform-none focus:outline-none focus:ring-teal-300 ${
                        startFreeze ? "" : "bg-transparent"
                      }`}
                      disabled={disabled}
                      onClick={handleAutoFreeze}
                    >
                      <div className="text-base font-normal leading-normal text-center text-white font-poppins">
                        {startFreeze ? "Stop" : "Start"}
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full flex flex-col gap-4">
              <div className="flex flex-col gap-1">
                <div className="flex gap-2 flex-1  justify-between">
                  {/* <div className="">
                    <input
                      className="w-20 h-8 px-3 py-3 bg-teal-600 bg-opacity-5 rounded-[10px] border border-gray-800 hover:border-baseColor focus:border-baseColor text-center"
                      value={whaleMaxBuy}
                      type="number"
                      step="0.1"
                      min={0}
                      onChange={(e) => setWhaleMaxBuy(e.target.value)}
                    />
                    <label className="w-50">Max buy</label>
                  </div> */}
                  <label className="text-center flex-1">Buyer Wallets</label>
                  <div className="flex gap-2">
                    {/* <div className="flex items-center gap-2">
                      <GrCaretPrevious
                        className="cursor-pointer"
                        onClick={() => {
                          handlePreviousPage();
                        }}
                      />
                      <label>{page + 1}</label>
                      <GrCaretNext
                        className="cursor-pointer"
                        disable={true}
                        onClick={() => {
                          handleNextPage();
                        }}
                      />
                    </div> */}
                    <button
                      className="h-8 w-8 rounded-full justify-center items-center gap-2.5 inline-flex border-none hover:bg-gradient-to-br active:scale-95 transition duration-100 ease-in-out transform focus:outline-none focus:ring-teal-300"
                      onClick={handleRefreshBuyers}
                    >
                      <RefreshIcon
                        className={`
                      size-4 relative ${refreshBuyers ? "animate-spin" : ""}`}
                      />
                    </button>
                  </div>
                </div>
                <div className="h-[200px] overflow-scroll border border-baseColor rounded-lg">
                  <table className="w-full text-left">
                    <thead className="">
                      <tr className="">
                        <th className="w-[10%] px-4 py-2 border-r border-gray-600 bg-slate-title bg-opacity-30 ">
                          <p className="block font-sans antialiased font-normal leading-none text-center text-white">
                            No
                          </p>
                        </th>
                        <th className="w-[50%] px-4 border-r border-gray-600 bg-slate-title bg-opacity-30">
                          <p className="block font-sans antialiased font-normal leading-none text-center text-white">
                            Address
                          </p>
                        </th>
                        <th className="w-[20%] px-4 border-r border-gray-600 bg-slate-title bg-opacity-30">
                          <p className="block font-sans antialiased font-normal leading-none text-center text-white">
                            Token
                          </p>
                        </th>
                        <th className="w-[20%] px-4 border-b border-none bg-slate-title bg-opacity-30 rounded-l-md">
                          <input
                            type="checkbox"
                            className="w-5 h-5 text-baseColor border-gray-200 rounded shrink-0 focus:ring-baseColor disabled:opacity-50 disabled:pointer-events-none dark:bg-gray-800 dark:border-gray-700 dark:checked:bg-baseColor dark:checked:border-baseColor dark:focus:ring-offset-gray-800"
                            checked={walletAllChecked}
                            disabled={disabled}
                            onChange={handleWalletAllChecked}
                          />
                        </th>
                      </tr>
                    </thead>
                    <tbody className="text-white text-base font-normal font-poppins leading-[24.93px]">
                      {buyers &&
                        buyers.length > 0 &&
                        buyers.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td className="w-[10%] p-4 border-b border-white border-opacity-30">
                                <p className="block font-sans antialiased font-normal leading-normal text-center text-white">
                                  {index + 1}
                                </p>
                              </td>
                              <td className="w-[50%] px-4 py-2 border-b border-white border-opacity-30">
                                <div className="flex items-center justify-center gap-1 font-sans antialiased font-normal leading-normal text-center text-teal-200">
                                  <p className="text-white bg-transparent outline-none">
                                    {ellipsisAddress(item)}
                                  </p>
                                  {copied["buyer" + index] ? (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="w-5 h-5 mx-1"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M5 13l4 4L19 7"
                                      />
                                    </svg>
                                  ) : (
                                    <FaRegCopy
                                      className="w-5 h-5 mx-1 transition duration-100 ease-in-out transform cursor-pointer active:scale-95 text-white"
                                      onClick={() =>
                                        copyToClipboard("buyer" + index, item)
                                      }
                                    />
                                  )}
                                </div>
                              </td>
                              <td className="w-[20%] px-4 py-2 border-b border-white border-opacity-30">
                                <p className="block font-sans antialiased font-normal leading-normal text-center text-white">
                                  {buyerTokenBalanceData[index]}
                                </p>
                              </td>
                              <td className="w-[20%] px-4 py-2 border-b border-white border-opacity-30">
                                <div className="flex justify-center">
                                  <input
                                    type="checkbox"
                                    className="w-5 h-5 text-baseColor border-gray-200 rounded shrink-0 focus:ring-baseColor disabled:opacity-50 disabled:pointer-events-none dark:bg-gray-800 dark:border-gray-700 dark:checked:bg-baseColor dark:checked:border-baseColor dark:focus:ring-offset-gray-800"
                                    disabled={disabled || disableManual}
                                    checked={walletChecked[index]}
                                    onChange={(e) =>
                                      handleWalletChanged(
                                        index,
                                        "checked",
                                        e.target.value
                                      )
                                    }
                                  />
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="flex flex-row gap-2">
                <div className="flex gap-1 flex-1  items-center">
                  <label className="text-center flex-1 pr-15">White List</label>
                </div>
                <div>
                  <button
                    className="h-12 w-full px-[25px] py-2.5 mt-2 mr-0 bg-gradient-to-r rounded-full border border-teal-600 disabled:bg-gray-600 disabled:from-gray-700 disabled:border-gray-600 justify-center items-center gap-2.5 inline-flex border-none hover:bg-gradient-to-br active:scale-95 transition duration-100 ease-in-out transform disabled:transform-none focus:outline-none focus:ring-teal-300"
                    onClick={handleManualFreeze}
                    disabled={disabled || disableManual}
                  >
                    <div className="text-base font-normal leading-normal text-center text-white font-poppins">
                      Manual Freeze
                    </div>
                  </button>
                </div>
              </div>
              <div className="h-[200px] overflow-scroll border border-baseColor rounded-lg">
                <table className="w-full text-left">
                  <thead className="">
                    <tr className="">
                      <th className="w-[10%] px-4 py-2 border-r border-gray-600 bg-slate-title bg-opacity-30 ">
                        <p className="block font-sans antialiased font-normal leading-none text-center text-white">
                          No
                        </p>
                      </th>
                      <th className="w-[50%] px-4 border-r border-gray-600 bg-slate-title bg-opacity-30">
                        <p className="block font-sans antialiased font-normal leading-none text-center text-white">
                          Address
                        </p>
                      </th>
                      <th className="w-[20%] px-4 border-r border-gray-600 bg-slate-title bg-opacity-30">
                        <p className="block font-sans antialiased font-normal leading-none text-center text-white">
                          Token
                        </p>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="text-white text-base font-normal font-poppins leading-[24.93px]">
                    {whiteWallets &&
                      whiteWallets.length > 0 &&
                      whiteWallets.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td className="w-[10%] p-4 border-b border-white border-opacity-30">
                              <p className="block font-sans antialiased font-normal leading-normal text-center text-white">
                                {index + 1}
                              </p>
                            </td>
                            <td className="w-[50%] px-4 py-2 border-b border-white border-opacity-30">
                              <div className="flex items-center justify-center gap-1 font-sans antialiased font-normal leading-normal text-center text-teal-200">
                                <p className="text-white bg-transparent outline-none">
                                  {ellipsisAddress(item)}
                                </p>
                              </div>
                            </td>
                            <td className="w-[20%] px-4 py-2 border-b border-white border-opacity-30">
                              <p className="block font-sans antialiased font-normal leading-normal text-center text-white">
                                {whiteTokenBalanceData[index]}
                              </p>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
