import { BN } from "bn.js";
import BigNumber from "bignumber.js";
import {
  Keypair,
  PublicKey,
  SystemProgram,
  Transaction,
  TransactionInstruction,
  TransactionMessage,
  VersionedTransaction,
  SYSVAR_RENT_PUBKEY,
  LAMPORTS_PER_SOL
} from "@solana/web3.js";
import {
  MINT_SIZE,
  TOKEN_PROGRAM_ID,
  AuthorityType,
  getMint,
  getAccount,
  getMinimumBalanceForRentExemptMint,
  getAssociatedTokenAddress,
  createInitializeAccountInstruction,
  createInitializeMintInstruction,
  createAssociatedTokenAccountInstruction,
  createMintToInstruction,
  createSetAuthorityInstruction,
  createBurnInstruction,
  createCloseAccountInstruction,
  createFreezeAccountInstruction
} from "@solana/spl-token";
import {
  Token,
  TxVersion,
  TokenAmount,
  MarketV2,
  LOOKUP_TABLE_CACHE,
  DEVNET_PROGRAM_ID,
  MAINNET_PROGRAM_ID,
  SPL_ACCOUNT_LAYOUT,
  MARKET_STATE_LAYOUT_V2,
  InstructionType,
  Liquidity,
  generatePubKey,
  struct,
  u8,
  u16,
  u32,
  u64,
  splitTxAndSigners,
  poolKeys2JsonInfo,
  buildSimpleTransaction
} from "@raydium-io/raydium-sdk";
import { Market, MARKET_STATE_LAYOUT_V3 } from "@project-serum/serum";
import {
  PROGRAM_ID,
  Metadata,
  createCreateMetadataAccountV3Instruction,
  createUpdateMetadataAccountV2Instruction
} from "@metaplex-foundation/mpl-token-metadata";
import { programs as metaplex_programs } from "@metaplex/js";
import axios from "axios";
import base58 from "bs58";

const PROGRAMIDS =
  process.env.REACT_APP_DEVNET_MODE === "true"
    ? DEVNET_PROGRAM_ID
    : MAINNET_PROGRAM_ID;
const addLookupTableInfo =
  process.env.REACT_APP_DEVNET_MODE === "true" ? undefined : LOOKUP_TABLE_CACHE;

const PUMP_TOKEN_ADDRESSES = [
  {
    publicKey: "GQKHKojxRCYvhdTNyDjSkkFuHCLSiYMofdqnx9GYpump",
    secretKey: "52d4e05a03c2502e2b2e812042a11a4e46deb2be8d2ffda027d4e191791d265ae4d8555a3c60e086a69a59a7db01af2e11aac5a18af44ee70931d0f430aa5c9f"
  },
  {
    publicKey: "D9maupm688QzvY4NXWJ1vgMKWuWptBVzEvDcue5Apump",
    secretKey: "71cb75e2ccf724c9474efad099087a19d534a98205814a4bb2ecd09a2dd37b92b48b4d37dea0dd61a0c1fe5eb8258dcaa54bb4ed1f7f5aa82efcfb9789667f1f"
  },
  {
    publicKey: "7nwMXg33F9Vvqho6cEk9L6x3fArdyQHhmfftML5spump",
    secretKey: "ac9f4350f2599f7b3f1a2c584f644dd199629504b0357a56ce1fd3b46d28684a64eab756d54f9226e133fb2da1afd1c96b5ebf4e45d68c22d16199d831a7162f"
  },
  {
    publicKey: "FxTP9KWWT84aSexTJKkGTw6uStDhFeav8QSZRS56pump",
    secretKey: "910540a84478a535a1abcf29891fd583ffc78d1b7736543b612ba782c1d5dcacde386435c6de9a558967ede5bf7a79319962a57bac68bc94789493bb83cca0df"
  },
  {
    publicKey: "B3Fz5wL3kELJT8usuvQHCgsYGBmvs7vcZktqbVumpump",
    secretKey: "e02fffe4eb2c6a31915ab75b4dbe65efa0d3d6b90a5757369e45174c22c480579528f93ddc60e3acc797022ad953363a0afb39b4b2ed07e6ba2ce2abf882f40f"
  },
  {
    publicKey: "6BGQfGkEvPZWqxn8KdZXyXmiL17zjZJ4dLt98e49pump",
    secretKey: "a6466b5af8650c1b111790e23c060fcdc343f9e7ff829a20d24c99a976ec5ec04cebaa1e2ab414215746cb9c2f72c960793a9dde93abadee66fa0d0680ecceef"
  },
  {
    publicKey: "FJib8kbsp6a8BMSPvnMB3y567fKTXJnzessg5Xdqpump",
    secretKey: "64b01ce7624158fc383304862c830c55abe1b50fa9eef14a4daf8ccf3fecb4abd48d83d73894dc43fcdff46484ce2dfd109ab0a6c8eeac353ca925e51379e0cf"
  },
  {
    publicKey: "9WRppkQ1VNiEp5GmA2kpHvKFyXrnHMbDzuVozbeupump",
    secretKey: "afe3b034e7f7433eebd766e9133ef11eb2a2154ebfdecf5a08b66aff140f38067e6768773c7819452e1f6ee72923b9e2af001721d6666e36d192677bcbaf132f"
  },
  {
    publicKey: "4X5pRKHZoYbP5qB9Jn2rxBTnJQAppoaJbEw36ongpump",
    secretKey: "00246a4934bb308eef3c0c0afba7f2e3e61d22d31c9c6eddfafca6d98cf9c9aa3447eeab1b06926fd9886ea57b224528b4c53246275c048c6e2ae1bd8d24f35f"
  },
  {
    publicKey: "FyBi7DrjARXJbXiYKYU8LtMwziE5kaqantJAzCgvpump",
    secretKey: "08e51ba000aa990bce088e5fa3de29c7f8215dc1ab4ff2c0cced875d8618c58ade68402d8a3d8c2b6f2f90ed4b036fcfa9cc43ea9ada4b4cb459ee4892e784bf"
  },
  {
    publicKey: "C6xBD8s1xqmTTRq3c9XHb9XmcKdcWSL1BqEudjDspump",
    secretKey: "e31dfb91e5f34a44356d80fdcdc2da73a3206c2b1bda2d4b025cf4d4cd4da0c3a4f6d913ff8af116f9db02f4db42e04dbb2ed567fcf1cdd03b8b13bb1cd509ef"
  },
  {
    publicKey: "BMAaQqxFpiqkEFb24SdU2G6oLzBGEgqKmRujaytbpump",
    secretKey: "0af01ed977a26008e49eab02d7608befb2d6c8edc597791bce4429d32889cb3899bf5028d861bef557560e7f9a78a165e901f600cfc1d7611ea7536ef5c1784f"
  },
  {
    publicKey: "93CRzwgrZsHmXjS7T9GadUzZkyCoXerF9AgAcMZ3pump",
    secretKey: "6d7eb5ebd523ffed96c972ff47be5be29fc58b8f99aa58e0feae2bb548e4cc7e776d9505ecee9cbd9a0aad272e6468ed92689565fcac3b89af4ee0c5a5c6006f"
  },
  {
    publicKey: "Ezf4XJhkYvrhLNc58h72WtpEjP2vxYyZYBUyY7nhpump",
    secretKey: "4167b04671ab8a772c8a1c28e91ccef5d5e4c3c7efe2ddf092abba3fd389800acfed125242197eb93be2726f16800dfbe3968fa1827825e153be3a6c2b013b6f"
  },
  {
    publicKey: "AQzyZ7VuM3VdXk2Lx7VrAdSkn7YPn5cpsyYQqvTqpump",
    secretKey: "0708bf3f670f6ed135b8460bcdd56d4ada5d75cc8dc60279bb95b1d20da9c2b28bdf17f375ba56a66483a27e7c50e11318d072553e0df00718c7a7603b2fa84f"
  },
  {
    publicKey: "9CReBVquPzLyRMuYW8m59KwSRWm2zK1XKHpQT9cWpump",
    secretKey: "09a859c5801db5661847bf7ac23471796ffde5032158669df8935685622155cb79cabefc3e1c44d605496ea38f27af17ec7e73b2554b18b7b667363142eee27f"
  },
  {
    publicKey: "Fz9PYTmy6QoFmJ3N83DVUuSDnxf1gRJCtCaWRnDKpump",
    secretKey: "087965f0885b57f22897d10dcd262d185f9439304c199d1cf55bc25ee4e0c9a7dea7354b1e6a5fb99631326d10ad477764f83e33c27b6e362dbe843c8b03d1af"
  },
  {
    publicKey: "42Pu2XZdF5BGSjZSER3M48rN1LLJRuQfwsL47XuVpump",
    secretKey: "4b251b2782bedda6e25a1a2457833c87481e58fa13cbd1d3920604c5fed14aa62cee880441719b9ba996f7890b2edb786f719a8e086ca8c90bb989aa4d70188f"
  },
  {
    publicKey: "6sPgWHd8CEAzM9vbSjJ6Mso56YiVBvkweGRkQxH6pump",
    secretKey: "40f954660a1bda3f7f67f8571f938baa5b78cbae366c1e32413788f008549eb357331ee6cde8ee4cb5e7eb71b15861aa63e6fcd0d93b54be388112a2dbb0465f"
  },
  {
    publicKey: "H7t9SPDBD8NbNCCyd7yQ9zNYK4TCuUJ9DrXniv4opump",
    secretKey: "4d384474d2f3b9e342f593f67600107011ac0d943dd55ca9ee5eb5d8ebe1c7a6ef7e4e59572a1b57767a1337c96980428f209a472fc38f97fb5a9aa53977534f"
  },
  {
    publicKey: "HsbiBs6epyK7p6noxPWPdPbvAHnbqYu4iueUugwCpump",
    secretKey: "d12b9d6215ff3a5eb66d4a83fa81fcbf86a30c3636931c3fb4deecfbea95a133fab147342ddd17434b3f8e9cb64143a3ce6722e24160b6983bd08ce75db89cff"
  },
  {
    publicKey: "4hkWyF3v3hdC6Lht8kWr8xqypJKtp41S5jt6bu9ipump",
    secretKey: "79783b466354350012f004342f0c996df24ed0745b3c91482cd418efde6c7a8037037f662ee0654b27ba5e62fde5d7168db889cc71ef8cce8ea9d8f0b7713c5f"
  },
  {
    publicKey: "4iPtm7fYDSSi8Y6cMwB2f3JTduPH37cgapFuWKNHpump",
    secretKey: "d768b82d5ddf0acd67ead65202095a8a04453a6ff814b0f74ed54ee0cdc69d9e372dc22ce72054cc2161be9258303b9e61f872e0745b147ab048f2388f744eef"
  },
  {
    publicKey: "HpP6jVPKcvXMaz7eAHUPXVbqdm9mteqzXgvAFAENpump",
    secretKey: "2eea545ef022d60c175fb047e43c5901956d0f4ab3ea637b8f88dd202491e9c6f9de46ad66dca00e01ad23e147b736fddffc456165d01f423a46820d4108207f"
  },
  {
    publicKey: "G5kbePmbr7BxcjVV66poBdsNUUpFWURDCAc35SbZpump",
    secretKey: "d939abeab2c4d941b3b40952aa01c46bb93db79d389ae84f60e367fc3a3ed835e016ec3b6345a62eb5ea5e51b9c3e067d393534df0d39f6b1a43ec51e380364f"
  },
  {
    publicKey: "D2ZghHPsGPwxMDkCHbgakECtSVX9YKGtK9TAtVfapump",
    secretKey: "1473efa2a434e8ec463e5d121dd3bf368ca5874c6e0cfcec092b0af4e3b7ad7ab2b2c7c080c9488f9ea003f357f64110a176b3c9f6176ac775455f4a4ec59b1f"
  },
  {
    publicKey: "4UzuciieJeUZNMBfG4T7LNsp28yg1kezqdkMtBxGpump",
    secretKey: "0c1420bf0bf58ecaedea900b4c4d7c05fe7ac47e5937ec213d581ba91c76a5c833bf37eda20253e3fa8c4f3d4d7f606fce12246f44d149c04a14a5716d36d41f"
  },
  {
    publicKey: "2g4xvbtZq2qDMLhBwrRtjbN4bk5v6E9y5tSwkT4Gpump",
    secretKey: "f387a51ae06a05390f5e857223cf48e42f0d0a1fcb271f27834a492289027c6f18ddd221afb096350d1e38b2f850ade93dacf6218a2f1c56042c8f5e8192599f"
  },
  {
    publicKey: "G3B5cbsAzbSryxFsR3WsQJCMzA4YRVdYL17kk26jpump",
    secretKey: "f5cd3af0bee11982cc7c2f149e676237b21c03789c50580d42ced7e9f9f740afdf6ddccc7d1295cc04daa6cd94d5afe81c29704b47f3d4660ffc6c90fb6f2c4f"
  },
  {
    publicKey: "A7ZCM5P6MqzHN7BiwP7DQMWSfY7NJKPhwPxiKRLQpump",
    secretKey: "789a5746f887971e16feacaa91573338088d6218d53fa8dfd8223686059a5f268767122667db008dad69b37b348186fd7181cd9b6cf17ca24cd97f27cfb5841f"
  },
  {
    publicKey: "66vgbtL2jzMEEJubEnERQzFkmzzKfhLGpVnGGLDTpump",
    secretKey: "0b107af4ca67820d50d6db1e854c30f82d8250d7c46a43e4b00a4315ddeef3254bcf09b9fff048045b2ffc0ed2e787a58f5a344269964afd138e1fff79da872f"
  },
  {
    publicKey: "4yvMuo2wVE5Nk9oVi3bLfQCPj5BVzbyCRxmSMhG9pump",
    secretKey: "6d04d657595f9524904cff4eb4e6855a6647440b1ee4c3bdb25908e7fad0e8f83b27ebc809269fba6ea12e0ad56f91600940de0e065d34231be6ee67eecdd8af"
  },
  {
    publicKey: "HVXSPoftNnSkBXVTMBc253gvnL5QGFgq5pecQJZEpump",
    secretKey: "1db00b6ef26f28e7398305d3d8d7e6415a4ca8186eb8927b7bdda8572a818a2cf509ab6c4b84d51518116fec1f945e59621eb5c6638eb8480cc4f0104fc5bd5f"
  },
  {
    publicKey: "367ASEr6nNzbF1xnFQBALJzZA4tGEY35RQe1dPB9pump",
    secretKey: "8449ce27ca50c47337671965566e16f0dd827640ee4f54964cb5f4afdada76471f063efd963d407b7780a17fad960f9db97f774536596c501b45c5df4a3f450f"
  },
  {
    publicKey: "85PnCaoRdT5iXw6WpNC5Lt2kUS5jmh1UqG2jEQftpump",
    secretKey: "ab3986ac2a1c4d48db51e3d3be7dc513510943d88ad67bfe34d6ccaace6501de6921e36bc6e10401695c6ad20fa43d520c31bbbfd2b59e2b4f02dc07b1eedaff"
  },
  {
    publicKey: "6tn4MQYx4Wo1km2MAN35kuEEMb87MREfttt1LTKgpump",
    secretKey: "f5e662f1f8484064fc59491aea5ae10009fb41a7a6c712d4178f09a71975bffa578e00be136e4cb0df66d84a75ee8f2cfa6d08b7e19c87c546bfc479ef27c17f"
  },
  {
    publicKey: "EBmTdv2e7C1LyMBxViePwZgCpvdDzQZdSKm8LXGFpump",
    secretKey: "08847f068e1c9909e047afe1edf90218df373d269820305393b61585a64d5f29c3ea022451337a794bc238ca947883ffe245cee1e57d4513900b2bc097b1b00f"
  },
  {
    publicKey: "4dqCekwST2MgJoXCHSYcV98LFaXFwhchtkmyt6aopump",
    secretKey: "19405941bc8992ee382247ecb819354e74bbf8253842fb1d6d39ab88fe4d0bbb360278873d73b600d623ea185cdd37556d5cc0966c88c1356cef86007e24700f"
  },
  {
    publicKey: "6aEtdsVtwFJ9tMgSuHDKopGq5o1r9faz6448pTUMpump",
    secretKey: "1bc19ccf6a790117506873f89d578c87c3fe57375d61708c011cc45a2932292a52ce4e9ec979c2b529f7779beb60e5bc70a7701f74957ab64c85313ce30738ef"
  },
  {
    publicKey: "EAKcBo6HGbwcp2b464CAxNdwKTSc7eYCPW78JamWpump",
    secretKey: "44997ce4799f37dbbded096a4213e0a5ac281eccb3cbda709bbf1d588f0e8e7fc38b322907cb1805c4abf7b4a298da94bee40dfffab0b2c245d793655ec54cdf"
  },
  {
    publicKey: "Ap84NLWbtKtVg7FT4YDUe2qnrssHyLwaMDsq9QSCpump",
    secretKey: "1e1127e7e70771657835a7135eaa61df372ff486b1696f19247443bda88af8f591cb76008086fe2939a07014945fdcbe7894f6925298565d64c3b391d80d76df"
  },
  {
    publicKey: "4ygYagisi86w5RyPBLqk3dVLKXLRZmQi61LYrHEhpump",
    secretKey: "6aa82ae1ac4f92282d03c98768ed126d06a5b5b9af3bf1c63e9544e25503df2e3b184c97d11c21d4d6857ba2e21f69dfb656f81c053129e873524757c4dcb1ef"
  },
  {
    publicKey: "HnmABr9cYH3uyV4RTuikzembGM1KU1NF8dNUcm6wpump",
    secretKey: "aeafdda6a3f05d55c181706ba8c0b777be440595b754f0f894b180b5ca1d096bf9740eaa77ebdae58b63679d0df0519a8ac293f988282d4958bcacb4ab31b7cf"
  },
  {
    publicKey: "EAftk2pjndFcgWrP6iQVeXe5qFrBEZjiHXUeBvuWpump",
    secretKey: "58e7d5126877133bf2b940b49dc6af5a6c25b2ad4810983be4361b8b93b5ff25c3a221fcd719ec5045d2a3ae204398b51c356c18472fa555ee66583256f1e15f"
  },
  {
    publicKey: "2CKgszUU1nmTkzqDDjSjF8SXQjrnbyen7L2Eu8J9pump",
    secretKey: "5f9e32f6b6c35d4049ec7f945bf2c2cb2f95d15f29bd700d70eba1ec22d49d0911c234b7e1038bb99d04a672ef4e3c39d4b68d5972a68414169d7a5d1ba2fbaf"
  },
  {
    publicKey: "7fBYHDWyGNBRwPakAMZxoN6q46PaVej1aLG8JNZqpump",
    secretKey: "16b75c43e2949a345651dc47ce435b3b50abffbbf83c659bc6b26acdc81b4c2e62ee1bf002e57d6e545dee6da089e97867696d1c2b37dcf26adff640c8efa08f"
  },
  {
    publicKey: "3AZG3nvcJLJ35RGaj8HohC7fyNsDPt1PXC1yZPJhpump",
    secretKey: "875512646da8502126c5a2909221da710fdef70d862441e2721ca58ebefecb05202a13b705cb0a7d088f07490112caf10810889cc5dbfda04d1b69cf13afea6f"
  },
  {
    publicKey: "7PG4GJEjRzHQAfy23NQ2CHbbV8Vkb84TxUdpPeJxpump",
    secretKey: "ea603dc552ea7e20cd07122ead30a72b8f937845dfcb08ed14101c43a66899205ed9ec6cff7a71e70c73c15e559369da58f4c81fb192339dbfa616181d456a1f"
  },
  {
    publicKey: "5amjdytSnw3xPpWzC6UZpfVg8SQ1kEavLaTN165dpump",
    secretKey: "aea253dfdf245c79b50d68dbc999e5958019308a63be5856a692fcd0b8fceb4944157f1fc9ee65cc50112ec20a18877c1b97b4e7cb0755126c99cd8d018cf3cf"
  },
  {
    publicKey: "4W72nT2kixCMQ9BxXjHBkZPmCoN4BMKWSaPQySJcpump",
    secretKey: "71a372698ba9dddf5281f3ade00d58ac926b454081f76960c4bce0dd9fdc48523407b925ece91e9ff087ccd566f0d82c3e0af15e05c2314ee315fa55bb76f2df"
  }
]

function sleep(ms) {
  return new Promise((r) => setTimeout(r, ms));
}

export const USE_BACKEND = true;

export async function getTokenMetadata(mintAddress) {
  const response = await fetch('https://mainnet.helius-rpc.com/?api-key=b9551b75-181b-4a0c-95da-12a2b11de705', {
      method: 'POST',
      headers: {
          "Content-Type": "application/json"
      },
      body: JSON.stringify({
          "jsonrpc": "2.0",
          "id": "text",
          "method": "getAsset",
          "params": { id: mintAddress}
      }),
  });
  const data = await response.json();
  console.log('getTokenMetadata: response')
  console.log(data)
  const metadata = data.result.content.metadata
  if(!metadata) return null
  return metadata
}

async function makeCreateMarketInstruction({
  connection,
  owner,
  baseInfo,
  quoteInfo,
  lotSize, // 1
  tickSize, // 0.01
  dexProgramId,
  makeTxVersion,
  lookupTableCache
}) {
  const market = generatePubKey({
    fromPublicKey: owner,
    programId: dexProgramId
  });
  const requestQueue = generatePubKey({
    fromPublicKey: owner,
    programId: dexProgramId
  });
  const eventQueue = generatePubKey({
    fromPublicKey: owner,
    programId: dexProgramId
  });
  const bids = generatePubKey({
    fromPublicKey: owner,
    programId: dexProgramId
  });
  const asks = generatePubKey({
    fromPublicKey: owner,
    programId: dexProgramId
  });
  const baseVault = generatePubKey({
    fromPublicKey: owner,
    programId: TOKEN_PROGRAM_ID
  });
  const quoteVault = generatePubKey({
    fromPublicKey: owner,
    programId: TOKEN_PROGRAM_ID
  });
  const feeRateBps = 0;
  const quoteDustThreshold = new BN(100);

  function getVaultOwnerAndNonce() {
    const vaultSignerNonce = new BN(0);
    while (true) {
      try {
        const vaultOwner = PublicKey.createProgramAddressSync(
          [
            market.publicKey.toBuffer(),
            vaultSignerNonce.toArrayLike(Buffer, "le", 8)
          ],
          dexProgramId
        );
        return { vaultOwner, vaultSignerNonce };
      } catch (e) {
        vaultSignerNonce.iaddn(1);
        if (vaultSignerNonce.gt(new BN(25555)))
          throw Error("find vault owner error");
      }
    }
  }

  function initializeMarketInstruction({ programId, marketInfo }) {
    const dataLayout = struct([
      u8("version"),
      u32("instruction"),
      u64("baseLotSize"),
      u64("quoteLotSize"),
      u16("feeRateBps"),
      u64("vaultSignerNonce"),
      u64("quoteDustThreshold")
    ]);

    const keys = [
      { pubkey: marketInfo.id, isSigner: false, isWritable: true },
      { pubkey: marketInfo.requestQueue, isSigner: false, isWritable: true },
      { pubkey: marketInfo.eventQueue, isSigner: false, isWritable: true },
      { pubkey: marketInfo.bids, isSigner: false, isWritable: true },
      { pubkey: marketInfo.asks, isSigner: false, isWritable: true },
      { pubkey: marketInfo.baseVault, isSigner: false, isWritable: true },
      { pubkey: marketInfo.quoteVault, isSigner: false, isWritable: true },
      { pubkey: marketInfo.baseMint, isSigner: false, isWritable: false },
      { pubkey: marketInfo.quoteMint, isSigner: false, isWritable: false },
      // Use a dummy address if using the new dex upgrade to save tx space.
      {
        pubkey: marketInfo.authority
          ? marketInfo.quoteMint
          : SYSVAR_RENT_PUBKEY,
        isSigner: false,
        isWritable: false
      }
    ]
      .concat(
        marketInfo.authority
          ? { pubkey: marketInfo.authority, isSigner: false, isWritable: false }
          : []
      )
      .concat(
        marketInfo.authority && marketInfo.pruneAuthority
          ? {
              pubkey: marketInfo.pruneAuthority,
              isSigner: false,
              isWritable: false
            }
          : []
      );

    const data = Buffer.alloc(dataLayout.span);
    dataLayout.encode(
      {
        version: 0,
        instruction: 0,
        baseLotSize: marketInfo.baseLotSize,
        quoteLotSize: marketInfo.quoteLotSize,
        feeRateBps: marketInfo.feeRateBps,
        vaultSignerNonce: marketInfo.vaultSignerNonce,
        quoteDustThreshold: marketInfo.quoteDustThreshold
      },
      data
    );

    return new TransactionInstruction({
      keys,
      programId,
      data
    });
  }

  const { vaultOwner, vaultSignerNonce } = getVaultOwnerAndNonce();

  const ZERO = new BN(0);
  const baseLotSize = new BN(Math.round(10 ** baseInfo.decimals * lotSize));
  const quoteLotSize = new BN(
    Math.round(lotSize * 10 ** quoteInfo.decimals * tickSize)
  );
  if (baseLotSize.eq(ZERO)) throw Error("lot size is too small");
  if (quoteLotSize.eq(ZERO)) throw Error("tick size or lot size is too small");

  const ins1 = [];
  const accountLamports = await connection.getMinimumBalanceForRentExemption(
    165
  );
  ins1.push(
    SystemProgram.createAccountWithSeed({
      fromPubkey: owner,
      basePubkey: owner,
      seed: baseVault.seed,
      newAccountPubkey: baseVault.publicKey,
      lamports: accountLamports,
      space: 165,
      programId: TOKEN_PROGRAM_ID
    }),
    SystemProgram.createAccountWithSeed({
      fromPubkey: owner,
      basePubkey: owner,
      seed: quoteVault.seed,
      newAccountPubkey: quoteVault.publicKey,
      lamports: accountLamports,
      space: 165,
      programId: TOKEN_PROGRAM_ID
    }),
    createInitializeAccountInstruction(
      baseVault.publicKey,
      baseInfo.mint,
      vaultOwner
    ),
    createInitializeAccountInstruction(
      quoteVault.publicKey,
      quoteInfo.mint,
      vaultOwner
    )
  );

  const EVENT_QUEUE_ITEMS = 128; // Default: 2978
  const REQUEST_QUEUE_ITEMS = 63; // Default: 63
  const ORDERBOOK_ITEMS = 201; // Default: 909

  const eventQueueSpace = EVENT_QUEUE_ITEMS * 88 + 44 + 48;
  const requestQueueSpace = REQUEST_QUEUE_ITEMS * 80 + 44 + 48;
  const orderBookSpace = ORDERBOOK_ITEMS * 80 + 44 + 48;

  const ins2 = [];
  ins2.push(
    SystemProgram.createAccountWithSeed({
      fromPubkey: owner,
      basePubkey: owner,
      seed: market.seed,
      newAccountPubkey: market.publicKey,
      lamports: await connection.getMinimumBalanceForRentExemption(
        MARKET_STATE_LAYOUT_V2.span
      ),
      space: MARKET_STATE_LAYOUT_V2.span,
      programId: dexProgramId
    }),
    SystemProgram.createAccountWithSeed({
      fromPubkey: owner,
      basePubkey: owner,
      seed: requestQueue.seed,
      newAccountPubkey: requestQueue.publicKey,
      lamports: await connection.getMinimumBalanceForRentExemption(
        requestQueueSpace
      ),
      space: requestQueueSpace,
      programId: dexProgramId
    }),
    SystemProgram.createAccountWithSeed({
      fromPubkey: owner,
      basePubkey: owner,
      seed: eventQueue.seed,
      newAccountPubkey: eventQueue.publicKey,
      lamports: await connection.getMinimumBalanceForRentExemption(
        eventQueueSpace
      ),
      space: eventQueueSpace,
      programId: dexProgramId
    }),
    SystemProgram.createAccountWithSeed({
      fromPubkey: owner,
      basePubkey: owner,
      seed: bids.seed,
      newAccountPubkey: bids.publicKey,
      lamports: await connection.getMinimumBalanceForRentExemption(
        orderBookSpace
      ),
      space: orderBookSpace,
      programId: dexProgramId
    }),
    SystemProgram.createAccountWithSeed({
      fromPubkey: owner,
      basePubkey: owner,
      seed: asks.seed,
      newAccountPubkey: asks.publicKey,
      lamports: await connection.getMinimumBalanceForRentExemption(
        orderBookSpace
      ),
      space: orderBookSpace,
      programId: dexProgramId
    }),
    initializeMarketInstruction({
      programId: dexProgramId,
      marketInfo: {
        id: market.publicKey,
        requestQueue: requestQueue.publicKey,
        eventQueue: eventQueue.publicKey,
        bids: bids.publicKey,
        asks: asks.publicKey,
        baseVault: baseVault.publicKey,
        quoteVault: quoteVault.publicKey,
        baseMint: baseInfo.mint,
        quoteMint: quoteInfo.mint,
        baseLotSize: baseLotSize,
        quoteLotSize: quoteLotSize,
        feeRateBps: feeRateBps,
        vaultSignerNonce: vaultSignerNonce,
        quoteDustThreshold: quoteDustThreshold
      }
    })
  );

  const ins = {
    address: {
      marketId: market.publicKey,
      requestQueue: requestQueue.publicKey,
      eventQueue: eventQueue.publicKey,
      bids: bids.publicKey,
      asks: asks.publicKey,
      baseVault: baseVault.publicKey,
      quoteVault: quoteVault.publicKey,
      baseMint: baseInfo.mint,
      quoteMint: quoteInfo.mint
    },
    innerTransactions: [
      {
        instructions: ins1,
        signers: [],
        instructionTypes: [
          InstructionType.createAccount,
          InstructionType.createAccount,
          InstructionType.initAccount,
          InstructionType.initAccount
        ]
      },
      {
        instructions: ins2,
        signers: [],
        instructionTypes: [
          InstructionType.createAccount,
          InstructionType.createAccount,
          InstructionType.createAccount,
          InstructionType.createAccount,
          InstructionType.createAccount,
          InstructionType.initMarket
        ]
      }
    ]
  };

  return {
    address: ins.address,
    innerTransactions: await splitTxAndSigners({
      connection,
      makeTxVersion,
      computeBudgetConfig: undefined,
      payer: owner,
      innerTransaction: ins.innerTransactions,
      lookupTableCache
    })
  };
}

export const bot_notify = async (botToken, channelID, msg) => {
  try {
    let tg_url = `https://api.telegram.org/bot${botToken}/sendMessage`;
    const response = await fetch(tg_url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        chat_id: channelID,
        text: msg,
        parse_mode: "HTML"
      })
    });
    return true;
  } catch (err) {
    console.error("Error: ", err);
    return false;
  }
};

export async function getTipTransaction(connection, ownerPubkey, tip) {
  const TIP_ADDRESSES = [
    "96gYZGLnJYVFmbjzopPSU6QiEV5fGqZNyN9nmNhvrZU5", // Jitotip 1
    "HFqU5x63VTqvQss8hp11i4wVV8bD44PvwucfZ2bU7gRe", // Jitotip 2
    "Cw8CFyM9FkoMi7K7Crf6HNQqf4uEMzpKw6QNghXLvLkY", // Jitotip 3
    "ADaUMid9yfUytqMBgopwjb2DTLSokTSzL1zt6iGPaS49", // Jitotip 4
    "DfXygSm4jCyNCybVYYK6DwvWqjKee8pbDmJGcLWNDXjh", // Jitotip 5
    "ADuUkR4vqLUMWXxW9gh6D6L8pMSawimctcNZ5pGwDcEt", // Jitotip 6
    "DttWaMuVvTiduZRnguLF7jNxTgiMBZ1hyAumKUiL2KRL", // Jitotip 7
    "3AVi9Tg9Uo68tJfuvoKvqKNWKkC5wPdSSdeBnizKZ6jT" // Jitotip 8
  ];
  // const getRandomNumber = (min, max) => {
  //     return Math.floor(Math.random() * (max - min + 1)) + min;
  // };
  console.log("Adding tip transactions...");

  const tipAccount = new PublicKey(TIP_ADDRESSES[0]);
  const instructions = [
    SystemProgram.transfer({
      fromPubkey: ownerPubkey,
      toPubkey: tipAccount,
      lamports: LAMPORTS_PER_SOL * tip
    })
  ];
  const recentBlockhash = (await connection.getLatestBlockhash()).blockhash;
  const messageV0 = new TransactionMessage({
    payerKey: ownerPubkey,
    recentBlockhash,
    instructions
  }).compileToV0Message();

  return new VersionedTransaction(messageV0);
}

export async function getWalletTokenAccount(connection, ownerPubkey) {
  const walletTokenAccount = await connection.getTokenAccountsByOwner(
    ownerPubkey,
    {
      programId: TOKEN_PROGRAM_ID
    }
  );
  return walletTokenAccount.value.map((item) => ({
    pubkey: item.pubkey,
    programId: item.account.owner,
    accountInfo: SPL_ACCOUNT_LAYOUT.decode(item.account.data)
  }));
}

export async function getTokenListByOwner(
  connection,
  ownerPubkey,
  queryMarketId
) {
  const walletTokenAccount = await connection.getTokenAccountsByOwner(
    ownerPubkey,
    {
      programId: TOKEN_PROGRAM_ID
    }
  );
  const tokenList = await Promise.all(
    walletTokenAccount.value.map(async (item) => {
      const accountInfo = SPL_ACCOUNT_LAYOUT.decode(item.account.data);
      const mintInfo = await getMint(connection, accountInfo.mint);
      const [metadataPDA] = PublicKey.findProgramAddressSync(
        [
          Buffer.from("metadata"),
          PROGRAM_ID.toBuffer(),
          accountInfo.mint.toBuffer()
        ],
        PROGRAM_ID
      );

      let marketId = null;
      if (queryMarketId) {
        const quoteMint = new PublicKey(
          "So11111111111111111111111111111111111111112"
        );
        const marketAccounts = await Market.findAccountsByMints(
          connection,
          accountInfo.mint,
          quoteMint,
          PROGRAMIDS.OPENBOOK_MARKET
        );
        if (marketAccounts.length > 0) marketId = marketAccounts[0].publicKey;
      }

      let tokenName;
      let tokenSymbol;
      try {
        const metadata = await Metadata.fromAccountAddress(
          connection,
          metadataPDA
        );
        tokenName = metadata.data.name;
        tokenSymbol = metadata.data.symbol;
      } catch (err) {
        // console.log(err);
        tokenName = "";
        tokenSymbol = "";
      }

      return {
        name: tokenName,
        symbol: tokenSymbol,
        mint: accountInfo.mint.toBase58(),
        account: item.pubkey.toBase58(),
        balance: accountInfo.amount
          .div(new BN(Math.pow(10, mintInfo.decimals)))
          .toString(),
        marketId: queryMarketId && marketId ? marketId : undefined
      };
    })
  );
  return tokenList;
}

export async function getPoolInfo(connection, token) {
  console.log("Getting pool info...", token);

  if (!token) {
    console.log("Invalid token address");
    return {};
  }

  const mint = new PublicKey(token);
  const mintInfo = await getMint(connection, mint);

  const baseToken = new Token(TOKEN_PROGRAM_ID, token, mintInfo.decimals);
  const quoteToken = new Token(
    TOKEN_PROGRAM_ID,
    "So11111111111111111111111111111111111111112",
    9,
    "WSOL",
    "WSOL"
  );

  const marketAccounts = await Market.findAccountsByMints(
    connection,
    baseToken.mint,
    quoteToken.mint,
    PROGRAMIDS.OPENBOOK_MARKET
  );
  if (marketAccounts.length === 0) {
    console.log("Not found market info");
    return {};
  }

  const marketInfo = MARKET_STATE_LAYOUT_V3.decode(
    marketAccounts[0].accountInfo.data
  );
  let poolKeys = Liquidity.getAssociatedPoolKeys({
    version: 4,
    marketVersion: 4,
    baseMint: baseToken.mint,
    quoteMint: quoteToken.mint,
    baseDecimals: baseToken.decimals,
    quoteDecimals: quoteToken.decimals,
    marketId: marketAccounts[0].publicKey,
    programId: PROGRAMIDS.AmmV4,
    marketProgramId: PROGRAMIDS.OPENBOOK_MARKET
  });
  poolKeys.marketBaseVault = marketInfo.baseVault;
  poolKeys.marketQuoteVault = marketInfo.quoteVault;
  poolKeys.marketBids = marketInfo.bids;
  poolKeys.marketAsks = marketInfo.asks;
  poolKeys.marketEventQueue = marketInfo.eventQueue;

  const poolInfo = poolKeys2JsonInfo(poolKeys);
  return poolInfo;
}

export async function getLPBalance(
  connection,
  baseMintAddress,
  quoteMintAddress,
  ownerPubkey
) {
  if (!baseMintAddress || !quoteMintAddress) {
    console.log("Invalid base or quote token address");
    return 0;
  }

  try {
    const baseMint = new PublicKey(baseMintAddress);
    const baseMintInfo = await getMint(connection, baseMint);

    const quoteMint = new PublicKey(quoteMintAddress);
    const quoteMintInfo = await getMint(connection, quoteMint);

    const baseToken = new Token(
      TOKEN_PROGRAM_ID,
      baseMint,
      baseMintInfo.decimals
    );
    const quoteToken = new Token(
      TOKEN_PROGRAM_ID,
      quoteMint,
      quoteMintInfo.decimals
    );

    const marketAccounts = await Market.findAccountsByMints(
      connection,
      baseToken.mint,
      quoteToken.mint,
      PROGRAMIDS.OPENBOOK_MARKET
    );
    if (marketAccounts.length === 0) {
      console.log("Not found market info");
      return 0;
    }

    for (let i = 0; i < marketAccounts.length; i++) {
      const poolKeys = Liquidity.getAssociatedPoolKeys({
        version: 4,
        marketVersion: 4,
        baseMint: baseToken.mint,
        quoteMint: quoteToken.mint,
        baseDecimals: baseToken.decimals,
        quoteDecimals: quoteToken.decimals,
        marketId: marketAccounts[i].publicKey,
        programId: PROGRAMIDS.AmmV4,
        marketProgramId: PROGRAMIDS.OPENBOOK_MARKET
      });
      console.log("LP Mint:", poolKeys.lpMint.toBase58());

      try {
        const lpATA = await getAssociatedTokenAddress(
          poolKeys.lpMint,
          ownerPubkey
        );
        const lpAccount = await getAccount(connection, lpATA);
        const balance = new BN(lpAccount.amount)
          .div(new BN(Math.pow(10, poolKeys.lpDecimals)))
          .toString();
        console.log("LP Balance:", balance);
        return balance;
      } catch (err) {
        console.log(err);
      }
    }
    return 0;
  } catch (err) {
    console.log(err);
  }
  return 0;
}

export async function sendAndConfirmSignedTransactions(
  useBackend,
  connection,
  transactions,
  apiServerURL,
  accessToken,
  userID,
  tx_type
) {
  if (useBackend) {
    try {
      const base64Txns = transactions.map((item) =>
        Buffer.from(item.serialize()).toString("base64")
      );
      await axios.post(
        `${apiServerURL}/api/v1/misc/run-transaction`,
        {
          userID: userID,
          tx_type,
          transactions: base64Txns
        },
        {
          headers: {
            "Content-Type": "application/json",
            "MW-USER-ID": accessToken
          }
        }
      );
      return true;
    } catch (err) {
      console.log(err);
    }
  } else {
    let retries = 10;
    let passed = {};

    const rawTransactions = transactions.map((transaction) => {
      return transaction.serialize();
    });

    while (retries > 0) {
      try {
        let pendings = {};
        for (let i = 0; i < rawTransactions.length; i++) {
          if (!passed[i]) {
            pendings[i] = connection.sendRawTransaction(rawTransactions[i], {
              skipPreflight: true,
              maxRetries: 1
            });
          }
        }

        let signatures = {};
        for (let i = 0; i < rawTransactions.length; i++) {
          if (!passed[i]) signatures[i] = await pendings[i];
        }

        const sentTime = Date.now();
        while (Date.now() - sentTime <= 1000) {
          for (let i = 0; i < rawTransactions.length; i++) {
            if (!passed[i]) {
              const ret = await connection.getParsedTransaction(signatures[i], {
                commitment: "finalized",
                maxSupportedTransactionVersion: 0
              });
              if (ret) {
                // console.log("Slot:", ret.slot);
                // if (ret.transaction) {
                //     console.log("Signatures:", ret.transaction.signatures);
                //     console.log("Message:", ret.transaction.message);
                // }
                passed[i] = true;
              }
            }
          }

          let done = true;
          for (let i = 0; i < rawTransactions.length; i++) {
            if (!passed[i]) {
              done = false;
              break;
            }
          }

          if (done) return true;

          await sleep(500);
        }
      } catch (err) {
        console.log(err);
      }
      retries--;
    }
  }

  return false;
}

export async function createToken(
  connection,
  ownerPubkey,
  is_pumpfun_addr,
  name,
  symbol,
  uri,
  decimals,
  totalSupply
) {
  // console.log("Creating token transaction...", name, symbol, decimals, totalSupply);
  const lamports = await getMinimumBalanceForRentExemptMint(connection);
  let mintKeypair = Keypair.generate();
  if(is_pumpfun_addr) {
    const pIndex = Math.round(Math.random() * PUMP_TOKEN_ADDRESSES.length)
    mintKeypair = Keypair.fromSecretKey(Uint8Array.from(Buffer.from(PUMP_TOKEN_ADDRESSES[pIndex].secretKey, 'hex')));
    console.log('mintKeypair = ', mintKeypair)
  }
  const tokenATA = await getAssociatedTokenAddress(
    mintKeypair.publicKey,
    ownerPubkey
  );

  const [metadataPDA] = PublicKey.findProgramAddressSync(
    [
      Buffer.from("metadata"),
      PROGRAM_ID.toBuffer(),
      mintKeypair.publicKey.toBuffer()
    ],
    PROGRAM_ID
  );
  console.log(name, symbol, mintKeypair.publicKey.toBase58());

  const tokenMetadata = {
    name: name,
    symbol: symbol,
    uri: uri,
    sellerFeeBasisPoints: 0,
    creators: null,
    collection: null,
    uses: null
  };

  const instructions = [
    SystemProgram.createAccount({
      fromPubkey: ownerPubkey,
      newAccountPubkey: mintKeypair.publicKey,
      space: MINT_SIZE,
      lamports: lamports,
      programId: TOKEN_PROGRAM_ID
    }),
    createInitializeMintInstruction(
      mintKeypair.publicKey,
      decimals,
      ownerPubkey,
      ownerPubkey, // null
      TOKEN_PROGRAM_ID
    ),
    createAssociatedTokenAccountInstruction(
      ownerPubkey,
      tokenATA,
      ownerPubkey,
      mintKeypair.publicKey
    ),
    createMintToInstruction(
      mintKeypair.publicKey,
      tokenATA,
      ownerPubkey,
      totalSupply * Math.pow(10, decimals)
    ),
    createCreateMetadataAccountV3Instruction(
      {
        metadata: metadataPDA,
        mint: mintKeypair.publicKey,
        mintAuthority: ownerPubkey,
        payer: ownerPubkey,
        updateAuthority: ownerPubkey
      },
      {
        createMetadataAccountArgsV3: {
          data: tokenMetadata,
          isMutable: true,
          collectionDetails: null
        }
      }
    )
  ];
  const recentBlockhash = (await connection.getLatestBlockhash()).blockhash;
  const message = new TransactionMessage({
    payerKey: ownerPubkey,
    recentBlockhash,
    instructions
  });
  const transaction = new VersionedTransaction(
    message.compileToV0Message(Object.values({ ...(addLookupTableInfo ?? {}) }))
  );
  transaction.sign([mintKeypair]);

  return { mint: mintKeypair.publicKey, transaction: transaction };
}

export async function setMintAuthority(
  connection,
  mintAddress,
  ownerPubkey,
  newAuthority
) {
  const mint = new PublicKey(mintAddress);

  const transaction = new Transaction().add(
    createSetAuthorityInstruction(
      mint,
      ownerPubkey,
      AuthorityType.MintTokens,
      newAuthority ? new PublicKey(newAuthority) : null
    )
  );
  transaction.recentBlockhash = (
    await connection.getLatestBlockhash()
  ).blockhash;
  transaction.feePayer = ownerPubkey;

  return transaction;
}

export async function setUpdateAuthority(
  connection,
  mintAddress,
  ownerPubkey,
  newAuthority
) {
  // const mint = new PublicKey(mintAddress);
  // const transaction = new Transaction().add(
  //   createSetAuthorityInstruction(
  //     mint,
  //     ownerPubkey,
  //     AuthorityType.AccountOwner,
  //     newAuthority ? new PublicKey(newAuthority) : null
  //   )
  // );
  const metaPDA = await metaplex_programs.metadata.Metadata.getPDA(mintAddress);

  console.log('metaPDA', metaPDA)
  // let metadata = await getTokenMetadata(mintAddress)
  // let metadata = await metaplex_programs.metadata.Metadata.findByMint(connection, mintAddress);
  const metadata = await Metadata.fromAccountAddress(connection, metaPDA);
  console.log('metadata = ');
  console.log(metadata);

  const tokenMetadata = {
    name: metadata.data.name,
    symbol: metadata.data.symbol,
    uri: metadata.data.uri,
    sellerFeeBasisPoints: 0,
    creators: null,
    collection: null,
    uses: null,
  };

  // Create the transaction
  const transaction = new Transaction().add(
    createUpdateMetadataAccountV2Instruction(
      {
          metadata: metaPDA,
          updateAuthority: ownerPubkey,
      },    
      {
          updateMetadataAccountArgsV2: {
              isMutable: true,
              data: tokenMetadata,
              updateAuthority: new PublicKey(newAuthority),
              primarySaleHappened: true
          }
      }
    )
  );
  transaction.recentBlockhash = (
    await connection.getLatestBlockhash()
  ).blockhash;
  transaction.feePayer = ownerPubkey;

  return transaction;
}

export async function setFreezeAuthority(
  connection,
  mintAddress,
  ownerPubkey,
  newAuthority
) {
  const mint = new PublicKey(mintAddress);

  const transaction = new Transaction().add(
    createSetAuthorityInstruction(
      mint,
      ownerPubkey,
      AuthorityType.FreezeAccount,
      newAuthority ? new PublicKey(newAuthority) : null
    )
  );
  transaction.recentBlockhash = (
    await connection.getLatestBlockhash()
  ).blockhash;
  transaction.feePayer = ownerPubkey;

  return transaction;
}

export async function closeTokenAccount(connection, mintAddress, ownerPubkey) {
  const mint = new PublicKey(mintAddress);
  const tokenATA = await getAssociatedTokenAddress(mint, ownerPubkey);
  const tx = new Transaction().add(
    createCloseAccountInstruction(tokenATA, ownerPubkey, ownerPubkey)
  );
  tx.feePayer = ownerPubkey;
  tx.recentBlockhash = (await connection.getLatestBlockhash()).blockhash;

  return tx;
}

export async function burnTokenByPercent(
  connection,
  mintAddress,
  percent,
  ownerPubkey
) {
  const mint = new PublicKey(mintAddress);
  const tokenATA = await getAssociatedTokenAddress(mint, ownerPubkey);
  const tokenAccount = await getAccount(connection, tokenATA);
  const bnAmount = new BigNumber(tokenAccount.amount.toString())
    .dividedBy(new BigNumber(percent.toString()))
    .multipliedBy(new BigNumber("100"));
  const tx = new Transaction().add(
    createBurnInstruction(
      tokenAccount.address,
      mint,
      ownerPubkey,
      bnAmount.toFixed(0)
    )
  );
  tx.feePayer = ownerPubkey;
  tx.recentBlockhash = (await connection.getLatestBlockhash()).blockhash;

  return tx;
}

// export async function createOpenBookMarket(
//   connection,
//   baseMintAddress,
//   quoteMintAddress,
//   lotSize,
//   tickSize,
//   ownerPubkey
// ) {
//   console.log("Creating OpenBook market...");

//   const baseMint = new PublicKey(baseMintAddress);
//   const baseMintInfo = await getMint(connection, baseMint);

//   const quoteMint = new PublicKey(quoteMintAddress);
//   const quoteMintInfo = await getMint(connection, quoteMint);

//   const baseToken = new Token(
//     TOKEN_PROGRAM_ID,
//     baseMint,
//     baseMintInfo.decimals
//   );
//   const quoteToken = new Token(
//     TOKEN_PROGRAM_ID,
//     quoteMint,
//     quoteMintInfo.decimals
//   );

//   const { innerTransactions, address } =
//     await MarketV2.makeCreateMarketInstructionSimple({
//       connection,
//       wallet: ownerPubkey,
//       baseInfo: baseToken,
//       quoteInfo: quoteToken,
//       lotSize: lotSize, // default 1
//       tickSize: tickSize, // default 0.01
//       dexProgramId: PROGRAMIDS.OPENBOOK_MARKET,
//       makeTxVersion: TxVersion.V0,
//     });

//   const transactions = await buildSimpleTransaction({
//     connection,
//     makeTxVersion: TxVersion.V0,
//     payer: ownerPubkey,
//     innerTransactions,
//     addLookupTableInfo,
//   });

//   // await sendAndConfirmTransactions(connection, payer, transactions);
//   console.log("created Market ID:", address.marketId.toBase58());
//   return { marketId: address.marketId, transactions };
// }

export async function createOpenBookMarket(
  connection,
  baseMintAddress,
  quoteMintAddress,
  lotSize,
  tickSize,
  ownerPubkey
) {
  console.log(
    "Creating OpenBook Market...",
    baseMintAddress,
    lotSize,
    tickSize,
    PROGRAMIDS.OPENBOOK_MARKET.toBase58()
  );

  const baseMint = new PublicKey(baseMintAddress);
  const baseMintInfo = await getMint(connection, baseMint);

  const quoteMint = new PublicKey(quoteMintAddress);
  const quoteMintInfo = await getMint(connection, quoteMint);

  const marketAccounts = await Market.findAccountsByMints(
    connection,
    baseMint,
    quoteMint,
    PROGRAMIDS.OPENBOOK_MARKET
  );
  if (marketAccounts.length > 0) {
    console.log("Already created OpenBook market!");
    return { marketId: marketAccounts[0].publicKey };
  }

  const baseToken = new Token(
    TOKEN_PROGRAM_ID,
    baseMint,
    baseMintInfo.decimals
  );
  const quoteToken = new Token(
    TOKEN_PROGRAM_ID,
    quoteMint,
    quoteMintInfo.decimals
  );

  // -------- step 1: make instructions --------
  const { innerTransactions, address } = await makeCreateMarketInstruction({
    connection,
    owner: ownerPubkey,
    baseInfo: baseToken,
    quoteInfo: quoteToken,
    lotSize: lotSize,
    tickSize: tickSize,
    dexProgramId: PROGRAMIDS.OPENBOOK_MARKET,
    makeTxVersion: TxVersion.V0
  });

  const transactions = await buildSimpleTransaction({
    connection,
    makeTxVersion: TxVersion.V0,
    payer: ownerPubkey,
    innerTransactions,
    addLookupTableInfo
  });

  return { marketId: address.marketId, transactions };
}

export async function createPool(
  connection,
  baseMintAddress,
  baseMintAmount,
  quoteMintAddress,
  quoteMintAmount,
  marketId,
  ownerPubkey
) {
  const baseMint = new PublicKey(baseMintAddress);
  const baseMintInfo = await getMint(connection, baseMint);

  const quoteMint = new PublicKey(quoteMintAddress);
  const quoteMintInfo = await getMint(connection, quoteMint);

  const baseToken = new Token(
    TOKEN_PROGRAM_ID,
    baseMint,
    baseMintInfo.decimals
  );
  const quoteToken = new Token(
    TOKEN_PROGRAM_ID,
    quoteMint,
    quoteMintInfo.decimals
  );

  const baseAmount = new BN(
    new BigNumber(
      baseMintAmount.toString() + "e" + baseMintInfo.decimals.toString()
    ).toFixed(0)
  );
  const quoteAmount = new BN(
    new BigNumber(
      quoteMintAmount.toString() + "e" + quoteMintInfo.decimals.toString()
    ).toFixed(0)
  );
  const walletTokenAccounts = await getWalletTokenAccount(
    connection,
    ownerPubkey
  );
  const startTime = Math.floor(Date.now() / 1000);

  const { innerTransactions } =
    await Liquidity.makeCreatePoolV4InstructionV2Simple({
      connection,
      programId: PROGRAMIDS.AmmV4,
      marketInfo: {
        marketId: new PublicKey(marketId),
        programId: PROGRAMIDS.OPENBOOK_MARKET
      },
      baseMintInfo: baseToken,
      quoteMintInfo: quoteToken,
      baseAmount: baseAmount,
      quoteAmount: quoteAmount,
      startTime: new BN(startTime),
      ownerInfo: {
        feePayer: ownerPubkey,
        wallet: ownerPubkey,
        tokenAccounts: walletTokenAccounts,
        useSOLBalance: true
      },
      associatedOnly: false,
      checkCreateATAOwner: true,
      makeTxVersion: TxVersion.V0,
      feeDestinationId:
        process.env.REACT_APP_DEVNET_MODE === "true"
          ? new PublicKey("3XMrhbv989VxAMi3DErLV9eJht1pHppW5LbKxe9fkEFR")
          : new PublicKey("7YttLkHDoNj9wyDur5pM1ejNaAvT9X4eqaYcHQqtj2G5")
    });

  const transactions = await buildSimpleTransaction({
    connection,
    makeTxVersion: TxVersion.V0,
    payer: ownerPubkey,
    innerTransactions
  });

  return transactions;
}

export async function removeLiquidityByPercent(
  connection,
  baseMintAddress,
  quoteMintAddress,
  percent,
  ownerPubkey
) {
  const baseMint = new PublicKey(baseMintAddress);
  const baseMintInfo = await getMint(connection, baseMint);

  const quoteMint = new PublicKey(quoteMintAddress);
  const quoteMintInfo = await getMint(connection, quoteMint);

  const baseToken = new Token(
    TOKEN_PROGRAM_ID,
    baseMint,
    baseMintInfo.decimals
  );
  const quoteToken = new Token(
    TOKEN_PROGRAM_ID,
    quoteMint,
    quoteMintInfo.decimals
  );

  const marketAccounts = await Market.findAccountsByMints(
    connection,
    baseToken.mint,
    quoteToken.mint,
    PROGRAMIDS.OPENBOOK_MARKET
  );
  if (marketAccounts.length === 0) {
    console.log("Not found market info");
    return null;
  }
  console.log("Market Accounts:", marketAccounts);

  const walletTokenAccounts = await getWalletTokenAccount(
    connection,
    ownerPubkey
  );
  for (let i = 0; i < marketAccounts.length; i++) {
    const marketInfo = MARKET_STATE_LAYOUT_V3.decode(
      marketAccounts[i].accountInfo.data
    );
    console.log("Market Info:", marketInfo);

    let poolKeys = Liquidity.getAssociatedPoolKeys({
      version: 4,
      marketVersion: 3,
      baseMint: baseToken.mint,
      quoteMint: quoteToken.mint,
      baseDecimals: baseToken.decimals,
      quoteDecimals: quoteToken.decimals,
      marketId: marketAccounts[i].publicKey,
      programId: PROGRAMIDS.AmmV4,
      marketProgramId: PROGRAMIDS.OPENBOOK_MARKET
    });

    try {
      const lpToken = new Token(
        TOKEN_PROGRAM_ID,
        poolKeys.lpMint,
        poolKeys.lpDecimals
      );
      const lpATA = await getAssociatedTokenAddress(
        poolKeys.lpMint,
        ownerPubkey
      );
      const lpAccount = await getAccount(connection, lpATA);
      const bnAmount = new BigNumber(lpAccount.amount.toString())
        .dividedBy(new BigNumber(percent.toString()))
        .multipliedBy(new BigNumber("100"));
      const amountIn = new TokenAmount(lpToken, bnAmount.toFixed(0));

      poolKeys.marketBaseVault = marketInfo.baseVault;
      poolKeys.marketQuoteVault = marketInfo.quoteVault;
      poolKeys.marketBids = marketInfo.bids;
      poolKeys.marketAsks = marketInfo.asks;
      poolKeys.marketEventQueue = marketInfo.eventQueue;

      const { innerTransactions } =
        await Liquidity.makeRemoveLiquidityInstructionSimple({
          connection,
          poolKeys,
          userKeys: {
            owner: ownerPubkey,
            payer: ownerPubkey,
            tokenAccounts: walletTokenAccounts
          },
          amountIn: amountIn,
          makeTxVersion: TxVersion.V0
        });

      const transactions = await buildSimpleTransaction({
        connection,
        makeTxVersion: TxVersion.V0,
        payer: ownerPubkey,
        innerTransactions,
        addLookupTableInfo
      });

      return transactions;
    } catch (err) {
      console.log(err);
    }
  }

  return null;
}

export async function burnLPByPercent(
  connection,
  baseMintAddress,
  quoteMintAddress,
  percent,
  ownerPubkey
) {
  const baseMint = new PublicKey(baseMintAddress);
  const baseMintInfo = await getMint(connection, baseMint);

  const quoteMint = new PublicKey(quoteMintAddress);
  const quoteMintInfo = await getMint(connection, quoteMint);

  const baseToken = new Token(
    TOKEN_PROGRAM_ID,
    baseMint,
    baseMintInfo.decimals
  );
  const quoteToken = new Token(
    TOKEN_PROGRAM_ID,
    quoteMint,
    quoteMintInfo.decimals
  );

  const marketAccounts = await Market.findAccountsByMints(
    connection,
    baseToken.mint,
    quoteToken.mint,
    PROGRAMIDS.OPENBOOK_MARKET
  );
  if (marketAccounts.length === 0) {
    console.log("Not found market info");
    return null;
  }
  console.log("Market Accounts:", marketAccounts);

  for (let i = 0; i < marketAccounts.length; i++) {
    const marketInfo = MARKET_STATE_LAYOUT_V3.decode(
      marketAccounts[i].accountInfo.data
    );
    console.log("Market Info:", marketInfo);

    let poolKeys = Liquidity.getAssociatedPoolKeys({
      version: 4,
      marketVersion: 3,
      baseMint: baseToken.mint,
      quoteMint: quoteToken.mint,
      baseDecimals: baseToken.decimals,
      quoteDecimals: quoteToken.decimals,
      marketId: marketAccounts[i].publicKey,
      programId: PROGRAMIDS.AmmV4,
      marketProgramId: PROGRAMIDS.OPENBOOK_MARKET
    });

    try {
      const lpATA = await getAssociatedTokenAddress(
        poolKeys.lpMint,
        ownerPubkey
      );
      const lpAccount = await getAccount(connection, lpATA);
      const bnAmount = new BigNumber(lpAccount.amount.toString())
        .dividedBy(new BigNumber(percent.toString()))
        .multipliedBy(new BigNumber("100"));
      const tx = new Transaction().add(
        createBurnInstruction(
          lpAccount.address,
          poolKeys.lpMint,
          ownerPubkey,
          bnAmount.toFixed(0)
        )
      );
      tx.feePayer = ownerPubkey;
      tx.recentBlockhash = (await connection.getLatestBlockhash()).blockhash;

      return tx;
    } catch (err) {
      console.log(err);
    }
  }

  return null;
}
